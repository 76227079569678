/**
 * Created by jerry on 2020/04/03.
 * 时效卡销售提成设置经手人 api
 */
import * as API from '@/api/index'
export default {
    // 获取时效卡销售组织单位提成方案列表
    getSaleTimeCardCommissionScheme: params => {
        return API.POST('api/saleTimeCardCommissionScheme/list', params)
    },
    // 创建时效卡销售组织单位提成方案
    createSaleTimeCardCommissionScheme: params => {
        return API.POST('api/saleTimeCardCommissionScheme/create', params)
    },
    // 删除时效卡销售提成方案
    deleteSaleTimeCardCommissionScheme: params => {
        return API.POST('api/saleTimeCardCommissionScheme/delete', params)
    },
    // 获取时效卡销售分类提佣方案
    getSaleTimeCardCategoryCommission: params => {
        return API.POST('api/saleTimeCardCategoryCommission/all', params)
    },
    // 保存时效卡销售分类提成方案
    updateSaleTimeCardCategoryCommission: params => {
        return API.POST('api/saleTimeCardCategoryCommission/update', params)
    },
    // 获取时效卡销售分类经手人提佣方案
    getSaleTimeCardCategoryHandlerCommission: params => {
        return API.POST('api/saleTimeCardCategoryHandlerCommission/all', params)
    },
    // 保存时效卡销售分类经手人提成方案
    updateSaleTimeCardCategoryHandlerCommission: params => {
        return API.POST('api/saleTimeCardCategoryHandlerCommission/update', params)
    },
    // 获取分类下的时效卡提佣方案
    getSaleTimeCardCommission: params => {
        return API.POST('api/saleTimeCardCommission/all', params)
    },
    // 保存时效卡销售提成方案
    updateSaleTimeCardCommission: params => {
        return API.POST('api/saleTimeCardCommission/update', params)
    },
    // 获取时效卡下经手人提佣方案
    getSaleTimeCardHanderCommission: params => {
        return API.POST('api/saleTimeCardHandlerCommission/all', params)
    },
    // 保存时效卡下经手人提成方案
    updateSaleTimeCardHanderCommission: params => {
        return API.POST('api/saleTimeCardHandlerCommission/update', params)
    },
    // 获取所有时效卡经手人提佣方案
    getSaleTimeCardSchemeHandlerCommission: params => {
        return API.POST('api/saleTimeCardSchemeHandlerCommission/all', params)
    },
    // 保存所有时效卡经手人提成方案
    updateSaleTimeCardSchemeHandlerCommission: params => {
        return API.POST('api/saleTimeCardSchemeHandlerCommission/update', params)
    },

    // 34.8.获取套餐卡时效卡销售分类经手人提佣方案
    getPackageSaleTimeCardCategoryHandlerCommission: params => {
      return API.POST('api/saleTimeCardCategoryHandlerCommission/packageCard', params)
    },
    // 34.13.获取所有套餐卡时效卡经手人提佣方案
    getPackageSaleTimeCardHandlerCommission: params => {
        return API.POST('api/saleTimeCardHandlerCommission/packageCard', params)
    },
    // 34.16.获所有套餐卡时效卡经手人提佣方案/
    getPackageSaleTimeCardSchemeHandlerCommission: params => {
        return API.POST('api/saleTimeCardSchemeHandlerCommission/packageCard', params)
    },

}