<template>
  <div class="content_body SaleTimeCardCommissionScheme" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small">
            <el-form-item label="组织单位">
              <el-input
                @clear="handleSearch"
                v-model="Name"
                placeholder="输入组织单位名称搜索"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="showAddDialog"
            v-prevent-click
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" ref="multipleTable" :data="tableData">
        <el-table-column label="组织单位" prop="EntityName"></el-table-column>

        <el-table-column label="操作" width="145px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(scope.row)"
              v-prevent-click
              >编辑</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="removeEntityClick(scope.row)"
              v-prevent-click
              v-if="isDelete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page pad_10 text_right">
        <div v-if="paginations.total > 0">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="paginations.page"
            :page-size="paginations.page_size"
            :layout="paginations.layout"
            :total="paginations.total"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!--新增弹窗-->
    <el-dialog
      title="新增时效卡销售提成方案"
      :visible.sync="dialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-form-item label="组织单位" prop="entity">
            <span slot="label">
              适用组织
              <el-popover placement="top-start" width="200" trigger="hover">
                <p>适用于同级所有节点，则只需选择父节点。</p>
                <p>比如：适用于所有节点，只需选择“顶级/第一个”节点。</p>
                <p>
                  如需要设置经手人/职务提成，请选择已经配置经手人的组织单位。
                </p>
                <el-button
                  type="text"
                  style="color: #dcdfe6"
                  icon="el-icon-info"
                  slot="reference"
                ></el-button>
              </el-popover>
            </span>
            <treeselect
              v-model="ruleForm.entity"
              :options="entityList"
              :normalizer="normalizer"
              clearValueText
              noResultsText="无匹配数据"
              placeholder="选择所属部门"
            />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitClick"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!--编辑弹窗-->
    <el-dialog :visible.sync="dialogEdit" width="70%">
      <div slot="title">{{ entityName }} - 时效卡分类销售提成方案</div>
      <el-table
        size="small"
        :data="tableDataEdit"
        row-key="CategoryID"
        :row-class-name="tableRowClassName"
        max-height="500px"
      >
        <el-table-column
          prop="CategoryName"
          label="时效卡分类"
          min-width="150px"
          fixed
        ></el-table-column>
        <el-table-column label="现金比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.PayRate"
              v-enter-number
              class="input_type"
              type="number"
              min="0"
              @input="royaltyRateChange(1, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="现金固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.PayFixed"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="卡抵扣比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardRate"
              v-enter-number
              class="input_type"
              type="number"
              min="0"
              @input="royaltyRateChange(2, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="卡抵扣固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardFixed"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="赠送卡抵扣比例" min-width="105px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardLargessRate"
              v-enter-number
              class="input_type"
              type="number"
              min="0"
              @input="royaltyRateChange(3, scope.row)"
            >
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="赠送卡抵扣固定" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SavingCardLargessFixed"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="特殊补贴" min-width="115px">
          <template slot-scope="scope">
            <el-input
              size="mini"
              v-model="scope.row.SpecialBenefit"
              v-enter-number2
              class="input_type"
              type="number"
              min="0"
            >
              <template slot="append">元</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="270px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handerCommission('0', scope.row)"
              v-if="scope.row.isEntity"
              >所有时效卡经手人/职务提成</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="handerCommission('1', scope.row)"
              v-if="!scope.row.isEntity"
              >分类经手人/职务提成</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="projectCommission(scope.row)"
              v-if="!scope.row.isProjectCommission && !scope.row.isEntity"
              >时效卡提成</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogEdit = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitClickEdit"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!--经手人弹窗-->
    <el-dialog :visible.sync="dialogHandler" width="60%">
      <div slot="title" v-if="whichHandlerDialog == '0'">
        {{ entityName }} - {{ categoryName }} - 经手人/职务销售提成方案
      </div>
      <div slot="title" v-else>
        {{ entityName }} - {{ categoryName }} - {{ productName }} -
        经手人/职务销售提成方案
      </div>
      <el-tabs v-model="handleActiveName" >
        <el-tab-pane label="时效卡" name="0">
          <el-table
            size="small"
            :data="handlerList"
            row-key="ID"
            :tree-props="{
              children: 'HandlerJobTypeCommission',
              hasChildren: 'hasHandlerJobTypeCommission',
            }"
            max-height="500px"
          >
            <el-table-column
              prop="Name"
              label="分类经手人/职务"
              min-width="150px"
              fixed
            ></el-table-column>
            <el-table-column label="现金比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(1, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="现金固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayFixed"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="卡扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(2, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardFixed"
              label="卡扣固定"
              min-width="115px"
            >
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardFixed"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡抵扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(3, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡抵扣固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessFixed"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="特殊补贴" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SpecialBenefit"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="套餐卡 - 时效卡" name="1">
          <el-table
            size="small"
            :data="packageHanderCommission"
            row-key="ID"
            :tree-props="{
              children: 'HandlerJobTypeCommission',
              hasChildren: 'hasHandlerJobTypeCommission',
            }"
            max-height="500px"
          >
            <el-table-column
              prop="Name"
              label="分类经手人/职务"
              min-width="150px"
              fixed
            ></el-table-column>
            <el-table-column label="现金比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(1, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="现金固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.PayFixed"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="卡扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(2, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="SavingCardFixed"
              label="卡扣固定"
              min-width="115px"
            >
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardFixed"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡抵扣比例" min-width="105px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessRate"
                  v-enter-number
                  class="input_type"
                  type="number"
                  min="0"
                  @input="royaltyRateChange(3, scope.row)"
                >
                  <template slot="append">%</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="赠送卡抵扣固定" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SavingCardLargessFixed"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="特殊补贴" min-width="115px">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  v-model="scope.row.SpecialBenefit"
                  v-enter-number2
                  class="input_type"
                  type="number"
                  min="0"
                >
                  <template slot="append">元</template>
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogHandler = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitHandlerClick"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!--时效卡提成设置-->
    <el-dialog :visible.sync="dialogTimeCard" width="65%">
      <div slot="title">
        {{ entityName }} - {{ categoryName }} - 时效卡销售提成方案
      </div>
      <div>
        <el-form :inline="true" size="small">
          <el-form-item>
            <el-input
              v-model="SearchKey"
              placeholder="输入时效卡名称搜索"
              prefix-icon="el-icon-search"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <el-table
          :data="
            timeCardList.filter(
              (data) =>
                !SearchKey ||
                data.TimeCardName.toLowerCase().includes(
                  SearchKey.toLowerCase()
                )
            )
          "
          row-key="TimeCardID"
          size="small"
          max-height="500px"
        >
          <el-table-column
            prop="TimeCardName"
            label="时效卡名称"
            min-width="150px"
            fixed
          ></el-table-column>
          <el-table-column label="现金比例" min-width="105px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.PayRate"
                v-enter-number
                class="input_type"
                min="0"
                type="number"
                @input="royaltyRateChange(1, scope.row)"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="现金固定" min-width="115px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.PayFixed"
                v-enter-number2
                class="input_type"
                type="number"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="SavingCardRate"
            label="储值卡抵扣比例"
            min-width="105px"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.SavingCardRate"
                v-enter-number
                class="input_type"
                type="number"
                min="0"
                @input="royaltyRateChange(2, scope.row)"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="SavingCardFixed"
            label="储值卡抵扣固定"
            min-width="115px"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.SavingCardFixed"
                v-enter-number2
                class="input_type"
                type="number"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="赠送卡抵扣比例" min-width="105px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.SavingCardLargessRate"
                v-enter-number
                class="input_type"
                type="number"
                min="0"
                @input="royaltyRateChange(3, scope.row)"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="赠送卡抵扣固定" min-width="115px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.SavingCardLargessFixed"
                v-enter-number2
                class="input_type"
                type="number"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="特殊补贴" min-width="115px">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.SpecialBenefit"
                v-enter-number2
                class="input_type"
                type="number"
                min="0"
              >
                <template slot="append">元</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="115px">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="handlerRoyalty(scope.row)"
                >经手人提成</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogTimeCard = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          @click="submitTimeCardClick"
          >保 存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import APIEntity from "@/api/KHS/Entity/entity";
import API from "@/api/iBeauty/HanderCommission/saleTimeCardCommissionScheme";
import permission from "@/components/js/permission.js";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
var Enumerable = require("linq");

export default {
  components: { Treeselect },
  name: "SaleTimeCardCommissionScheme",
  data() {
    return {
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      dialogEdit: false,
      isDelete: false, // 删除按钮状态
      dialogHandler: false, //  经手人弹框状态
      dialogTimeCard: false, // 时效卡提成设置弹框状态
      SearchKey: "", // 时效卡提成设置弹框搜索框数据
      handlerList: [], // 经手人设置列表
      packageHanderCommission: [],
      timeCardList: [], // 时效卡提成设置列表
      Name: "",
      entityName: "", // 单位名称
      categoryName: "", // 分类名称
      productName: "", // 时效卡名称
      whichHandlerDialog: "", // 经手人弹框类型
      handlerType: "", // 当前经手人弹框类型
      entityList: [],
      tableData: [],
      tableData3: [],
      EntityID: "", // 组织单位ID
      tableDataEdit: [], // 编辑表格数据
      cardCategoryCommission: [], // 保存销售分类提成参数
      CategoryID: "", // 时效卡分类ID
      TimeCardID: "", // 时效卡ID
      ruleForm: {
        entity: null,
      },
      rules: {
        entity: [{ required: true, message: "请选择组织", trigger: "change" }],
      },
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      handleActiveName:"0"
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(
        to.meta.Permission,
        "iBeauty-HanderCommission-SaleTimeCardCommissionScheme-Delete"
      );
    });
  },
  methods: {
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.getSaleTimeCardCommissionScheme();
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getSaleTimeCardCommissionScheme();
    },
    // 所属单位
    entityData: function () {
      var that = this;
      APIEntity.getEntityAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取时效卡销售组织单位提成方案列表
    getSaleTimeCardCommissionScheme: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        Name: that.Name,
      };
      API.getSaleTimeCardCommissionScheme(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = Object.assign([], res.List);
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 新增
    showAddDialog: function () {
      var that = this;
      that.ruleForm = {
        entity: null,
      };
      that.dialogVisible = true;
    },
    // 新增提交
    submitClick: function () {
      var that = this;
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.createSaleTimeCardCommissionScheme();
        }
      });
    },
    // 创建时效卡销售组织单位提成方案
    createSaleTimeCardCommissionScheme: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        EntityID: that.ruleForm.entity,
      };
      API.createSaleTimeCardCommissionScheme(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.getSaleTimeCardCommissionScheme();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 编辑
    showEditDialog: function (row) {
      var that = this;
      that.EntityID = row.EntityID;
      that.dialogEdit = true;
      that.entityName = row.EntityName;
      that.getSaleTimeCardCategoryCommission();
    },
    // 获取时效卡销售分类提佣方案
    getSaleTimeCardCategoryCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
      };
      API.getSaleTimeCardCategoryCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.cardCategoryCommission = res.Data;
            var data = {
              EntityID: res.Data.EntityID,
              EntityName: res.Data.EntityName,
              CategoryID: "0" + res.Data.EntityID,
              CategoryName: "所有时效卡",
              categoryName: "所有时效卡",
              PayFixed: res.Data.PayFixed,
              PayRate: res.Data.PayRate,
              SavingCardFixed: res.Data.SavingCardFixed,
              SavingCardLargessFixed: res.Data.SavingCardLargessFixed,
              SavingCardLargessRate: res.Data.SavingCardLargessRate,
              SavingCardRate: res.Data.SavingCardRate,
              SpecialBenefit: res.Data.SpecialBenefit,
              isEntity: true,
            };
            var Category = Enumerable.from(res.Data.Category)
              .select((val) => ({
                CategoryID: val.CategoryID,
                CategoryName: val.CategoryName,
                categoryName: val.CategoryName,
                PayFixed: val.PayFixed,
                PayRate: val.PayRate,
                SavingCardFixed: val.SavingCardFixed,
                SavingCardLargessFixed: val.SavingCardLargessFixed,
                SavingCardLargessRate: val.SavingCardLargessRate,
                SavingCardRate: val.SavingCardRate,
                SpecialBenefit: val.SpecialBenefit,
              }))
              .toArray();
            that.tableDataEdit = Object.assign([], Category);
            that.tableDataEdit.unshift(data);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 编辑提交
    submitClickEdit() {
      var that = this;
      var Category = Object.assign([], that.tableDataEdit);
      var Entity = [];
      Category.forEach((item, index) => {
        if (item.isEntity) {
          Entity = item;
          Category.splice(index, 1);
        }
      });
      that.cardCategoryCommission.EntityID = Entity.EntityID;
      that.cardCategoryCommission.PayFixed = Entity.PayFixed;
      that.cardCategoryCommission.PayRate = Entity.PayRate;
      that.cardCategoryCommission.SavingCardFixed = Entity.SavingCardFixed;
      that.cardCategoryCommission.SavingCardRate = Entity.SavingCardRate;
      that.cardCategoryCommission.SavingCardLargessFixed =
        Entity.SavingCardLargessFixed;
      that.cardCategoryCommission.SavingCardLargessRate =
        Entity.SavingCardLargessRate;
      that.cardCategoryCommission.SpecialBenefit = Entity.SpecialBenefit;
      that.cardCategoryCommission.Category = Category;

      that.cardCategoryCommission.Category = Enumerable.from(
        that.cardCategoryCommission.Category
      )
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
          );
        })
        .toArray();
      that.updateSaleTimeCardCategoryCommission();
    },
    // 保存时效卡销售分类提成方案
    updateSaleTimeCardCategoryCommission: function () {
      var that = this;
      that.modalLoading = true;
      var params = that.cardCategoryCommission;
      API.updateSaleTimeCardCategoryCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogEdit = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 删除
    removeEntityClick: function (row) {
      var that = this;
      this.$confirm("此操作将永久删除该记录, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.modalLoading = false;
          var params = {
            EntityID: row.EntityID,
          };
          API.deleteSaleTimeCardCommissionScheme(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success("成功");
                that.getSaleTimeCardCommissionScheme();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.modalLoading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 时效卡分类经手人提成
    handerCommission: function (type, row) {
      var that = this;
      that.handleActiveName = "0"
      that.CategoryID = row.CategoryID;
      that.whichHandlerDialog = "0";
      that.dialogHandler = true;
      that.categoryName = row.categoryName;
      that.handlerType = type;
      if (type == "0") {
        that.getSaleTimeCardSchemeHandlerCommission();
        that.PackageSaleTimeCardSchemeHandlerCommission();
      } else {
        that.getSaleTimeCardCategoryHandlerCommission();
        that.PackageSaleTimeCardCategoryHandlerCommission();
      }
    },
    // 获取时效卡销售分类经手人提佣方案
    getSaleTimeCardCategoryHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.CategoryID,
      };
      API.getSaleTimeCardCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 套餐卡 获取时效卡销售分类经手人提佣方案
    PackageSaleTimeCardCategoryHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.CategoryID,
      };
      API.getPackageSaleTimeCardCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 获取所有时效卡经手人提佣方案
    getSaleTimeCardSchemeHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
      };
      API.getSaleTimeCardSchemeHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 套餐卡 获取所有时效卡经手人提佣方案
    PackageSaleTimeCardSchemeHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
      };
      API.getPackageSaleTimeCardSchemeHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 时效卡下经手人提佣点击事件
    handlerRoyalty(row) {
      var that = this;
      that.handleActiveName = "0"
      that.TimeCardID = row.TimeCardID;
      that.productName = row.TimeCardName;
      that.dialogHandler = true;
      that.whichHandlerDialog = "1";
      that.getSaleTimeCardHanderCommission();
      that.PackageSaleTimeCardHandlerCommission();
    },
    // 获取时效卡下经手人提佣方案
    getSaleTimeCardHanderCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        TimeCardID: that.TimeCardID,
      };
      API.getSaleTimeCardHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.handlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 获取时效卡下经手人提佣方案
    PackageSaleTimeCardHandlerCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        TimeCardID: that.TimeCardID,
      };
      API.getPackageSaleTimeCardHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageHanderCommission = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    // 提交经手人
    submitHandlerClick() {
      var that = this;
      that.handlerList.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      that.handlerList = Enumerable.from(that.handlerList)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .toArray();
      that.handlerList.forEach((val) => {
        val.SaleHandlerID = val.ID;
      });
      // 套餐卡
      that.packageHanderCommission.forEach(function (item) {
        item.HandlerJobTypeCommission = Enumerable.from(
          item.HandlerJobTypeCommission
        )
          .where(function (i) {
            return (
              (i.PayFixed !== "" && i.PayFixed !== null) ||
              (i.PayRate !== "" && i.PayRate !== null) ||
              (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
              (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
              (i.SavingCardLargessFixed !== "" &&
                i.SavingCardLargessFixed !== null) ||
              (i.SavingCardLargessRate !== "" &&
                i.SavingCardLargessRate !== null) ||
              (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
            );
          })
          .toArray();
      });
      that.packageHanderCommission = Enumerable.from(that.packageHanderCommission)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null) ||
            i.HandlerJobTypeCommission.length > 0
          );
        })
        .toArray();
      that.packageHanderCommission.forEach((val) => {
        val.SaleHandlerID = val.ID;
      });

      if (that.whichHandlerDialog == "0") {
        if (that.handlerType == "0") {
          that.updateSaleTimeCardSchemeHandlerCommission();
        } else {
          that.updateSaleTimeCardCategoryHandlerCommission();
        }
      } else {
        that.updateSaleTimeCardHanderCommission();
      }
    },
    // 保存时效卡销售分类经手人提成方案
    updateSaleTimeCardCategoryHandlerCommission: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.CategoryID,
        TimeCardHandler: that.handlerList,
        PackageCardTimeCardHandler: that.packageHanderCommission,
      };
      API.updateSaleTimeCardCategoryHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogHandler = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 保存所有时效卡经手人提成方案
    updateSaleTimeCardSchemeHandlerCommission: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        TimeCardHandler: that.handlerList,
        PackageCardTimeCardHandler: that.packageHanderCommission,
      };
      API.updateSaleTimeCardSchemeHandlerCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogHandler = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 保存时效卡下经手人提成方案
    updateSaleTimeCardHanderCommission: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        TimeCardID: that.TimeCardID,
        TimeCardHandler: that.handlerList,
        PackageCardTimeCardHandler: that.packageHanderCommission,
      };
      API.updateSaleTimeCardHanderCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogHandler = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 时效卡提成设置
    projectCommission: function (row) {
      var that = this;
      that.CategoryID = row.CategoryID;
      that.categoryName = row.categoryName;
      that.getSaleTimeCardCommission();
      that.dialogTimeCard = true;
    },
    // 获取分类下的时效卡提佣方案
    getSaleTimeCardCommission: function () {
      var that = this;
      that.loading = true;
      var params = {
        EntityID: that.EntityID,
        CategoryID: that.CategoryID,
      };
      API.getSaleTimeCardCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.timeCardList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 提交时效卡提成设置
    submitTimeCardClick() {
      var that = this;
      that.timeCardList = Enumerable.from(that.timeCardList)
        .where(function (i) {
          return (
            (i.PayFixed !== "" && i.PayFixed !== null) ||
            (i.PayRate !== "" && i.PayRate !== null) ||
            (i.SavingCardFixed !== "" && i.SavingCardFixed !== null) ||
            (i.SavingCardRate !== "" && i.SavingCardRate !== null) ||
            (i.SavingCardLargessFixed !== "" &&
              i.SavingCardLargessFixed !== null) ||
            (i.SavingCardLargessRate !== "" &&
              i.SavingCardLargessRate !== null) ||
            (i.SpecialBenefit !== "" && i.SpecialBenefit !== null)
          );
        })
        .toArray();
      that.updateSaleTimeCardCommission();
    },
    // 保存时效卡销售提成方案
    updateSaleTimeCardCommission: function () {
      var that = this;
      that.modalLoading = true;
      var params = {
        EntityID: that.EntityID,
        TimeCard: that.timeCardList,
        CategoryID: that.CategoryID,
      };
      API.updateSaleTimeCardCommission(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogTimeCard = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    // 高亮第一级表格
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0) {
        return "info-row";
      }
      return "";
    },
    // 树形结构数据转换
    normalizer(node) {
      return {
        id: node.ID,
        label: node.EntityName,
        children: node.Child,
      };
    },
    // 约束提成比例
    royaltyRateChange: function (index, row) {
      if (index == 1) {
        if (row.PayRate > 100) {
          row.PayRate = 100;
        }
      } else if (index == 2) {
        if (row.SavingCardRate > 100) {
          row.SavingCardRate = 100;
        }
      } else if (index == 3) {
        if (row.SavingCardLargessRate > 100) {
          row.SavingCardLargessRate = 100;
        }
      }
    },
  },
  mounted() {
    var that = this;

      that.isDelete = permission.permission(
        that.$route.meta.Permission,
        "iBeauty-HanderCommission-SaleTimeCardCommissionScheme-Delete"
      );
    that.getSaleTimeCardCommissionScheme();
    that.entityData();
  },
};
</script>

<style lang="scss">
.SaleTimeCardCommissionScheme{

  .input_type {
    .el-input-group__append {
      padding: 0 10px;
    }
  }
  .el-table .info-row {
    background: #c0c4cc;
  }
  .el-input__inner {
    padding-right: 0;
  }
}
</style>
